html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

@font-face {
  font-family: "coder";
  src: local("coder"), url(AlbulaPro.woff) format("woff");
}

body {
  margin: 0;
  font-family: "coder", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body::-webkit-scrollbar {
  display: none;
}

body {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

html {
  background-color: "#4C4C4C";
}

div {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -o-user-select: none;
}

@media only screen and (max-width: 767px) {
  p.sc-gKseQn.kOAlVm {
    font-size: initial !important;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.popup-container {
  font-family: Arial, sans-serif;
  line-height: 1.6;
}

.popup-container img {
  cursor: pointer;
  transition: transform 0.1s;
}

.popup-container img:active {
  transform: scale(0.95);
}

.popup-overlay {
  opacity: 0;
  animation: fadeIn 0.5s forwards;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-box {
  position: relative;
  width: 90%; /* Use a percentage to make it responsive */
  max-width: 600px; /* Limit the maximum width */
  padding: 2rem; /* Use rem for scalable padding */
  background-color: #2a2a2a;
  color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.popup-box h2 {
  margin-bottom: 20px;
  font-size: 20px;
  color: #fff;
}

.popup-box p {
  margin-bottom: 20px;
  font-size: 16px;
  color: #ddd;
}

.info-label {
  color: #aaa;
}

.info-value {
  color: #fff;
}

.claim-button,
.close-button,
.claim-tokens-button {
  padding: 10px 20px;
  margin: 10px;
  border: none;
  border-radius: 5px;
  font-size: 18px;
  font-weight: bold;
  transition: transform 0.1s;
  color: #2a2a2a;
}

.claim-button:active,
.close-button:active,
.claim-tokens-button:active {
  transform: scale(0.95);
}

.claim-button,
.claim-tokens-button {
  background-color: #4caf50;
}

.claim-button[disabled],
.claim-tokens-button[disabled] {
  background-color: #9e9e9e;
}
.close-button {
  position: absolute;
  top: 10px; /* Adjust the distance from the top as needed */
  right: 10px; /* Adjust the distance from the right as needed */
  background-color: transparent;
  border: none;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
  color: #fff; /* Using white for visibility against the dark overlay */
  padding: 10px;
  line-height: 1;
  transition: color 0.2s;
}

.close-button:hover {
  color: #faf7f7; /* Adjust hover color as needed */
}
